<template>
  <el-dialog
    :close-on-click-modal="false"
    v-elDragDialog
    :title="$t('deviceModal.selectDeviceModel')"
    v-loading="loading"
    :visible.sync="show"
    width="820px"
    append-to-body
  >
    <div class="header">
      <div class="left">
        <el-input
          v-model.trim="name"
          @keyup.enter.native="replaceData"
          :placeholder="$t('publishApp.enterDeviceModel')"
        />
        <el-button type="primary" plain @click="getData">{{ $t('search') }}</el-button>
      </div>
      <div class="right">
        <el-checkbox v-model="isAll" @change="allHandler">{{ $t('publishApp.whole') }}</el-checkbox>
      </div>
    </div>

    <div class="content">
      <el-checkbox-group v-model="checks" @change="changHandler" v-if="list.length">
        <el-checkbox class="item" v-for="item in list" :key="item.id" :label="item.id">
          <div class="item-left">
            <img :src="item.imagePath" />
            <div>
              <span class="name">{{ item.name }}</span>
              <span class="num"
                >{{ $t('publishApp.common') }} {{ item.deviceTotal || 0 }}
                {{ $t('adminHomePage.tower') }}{{ $t('deviceModal.devices') }}</span
              >
            </div>
          </div>
        </el-checkbox>
      </el-checkbox-group>
      <p class="no-data" v-else>{{ $t('noData') }}</p>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="submit">{{ $t('confirm') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
export default {
  mixins: [dialogCommonParams],
  props: ['selectData'],
  components: {},
  data() {
    return {
      name: '',
      list: [],
      isAll: false,
      checks: [],
      checkData: [],
      loading: false
    }
  },
  created() {
    this.getData()
    if (this.selectData.length) {
      this.checkData = [...this.selectData]
      this.init()
    }
  },
  methods: {
    init() {
      this.checkData = [...this.selectData]
      this.checks = this.checkData.map(item => item.id)
    },
    async getData() {
      try {
        this.loading = true
        const res = await this.$api.deviceManagement.modelList({ name: this.name, pageSize: -1 })
        this.list = (res.result && res.result.list) || []
      } catch (err) {
      } finally {
        this.loading = false
      }
    },
    changHandler() {
      this.checkData = this.checks.map(val => {
        return this.list.find(item => item.id === val)
      })
    },
    allHandler(bool) {
      if (bool) {
        this.checkData = [...this.list]
        this.checks = this.checkData.map(item => item.id)
      } else {
        this.checkData = []
        this.checks = []
      }
    },
    submit() {
      if (!this.checkData.length) {
        return this.$message.warning(this.$t('deviceModal.pSelectDeviceModel'))
      }
      this.$emit('update:selectData', this.checkData)
      this.show = false
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left {
    display: flex;
    button {
      margin-left: 10px;
    }
  }
}
.content {
  margin-top: 10px;
  height: 500px;
  overflow: auto;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
  }
  ::v-deep .el-checkbox {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    .el-checkbox__label {
      flex: 1;
      padding: 0;
    }
  }

  .item {
    margin-right: 12px;
    margin-top: 10px;
    width: 150px;
    border: 1px solid #ccc;
    padding: 10px;
    overflow: hidden;
    border-radius: 4px;

    .item-left {
      display: flex;
      flex: 1;
      line-height: 18px;
      img {
        width: 46px;
        height: 46px;
        object-fit: contain;
      }
      .name {
        font-size: 13px;
      }
      .num {
        font-size: 12px;
        color: #ccc;
      }
    }

    .svg-icon {
      width: 25px;
      height: 25px;
    }

    .item-left > div {
      margin-left: 10px;
      span {
        display: block;
        max-width: 70px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>
